import { Link } from 'gatsby-plugin-intl';
import React from 'react';
import { buttons, page, spacing, utils } from 'style';

const NotFoundPage: React.FC = () => (
  <>
    <section css={[page.root, page.centered]}>
      <header css={page.header}>
        <h1>Page not found</h1>
      </header>
      <div css={[page.content, utils.fontFamilySansAlt, spacing.bottom.large]}>
        <p css={utils.typeLarge}>
          The URL you entered does not exist. Go back to homepage using the
          button below.
        </p>
      </div>
      <Link
        to="/"
        css={[
          buttons.base,
          buttons.neutral,
          buttons.narrowButton,
          spacing.top.xlarge,
        ]}
      >
        Back to Homepage
      </Link>
    </section>
  </>
);

export default NotFoundPage;
